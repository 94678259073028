@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-image-scroll {
    background-image: url('https://www.google.com/search?q=image&sca_esv=94b417bf3c247d44&sxsrf=ADLYWIL8f0K63ldRTOMyvfGTY-aaoq73Cw:1721015536922&udm=2&source=iu&ictx=1&vet=1&fir=tYmxDgFq4MrkJM%252C-t22bY2ix3gHaM%252C%252Fm%252F0jg24%253BPG4lSDrYNAQW-M%252CVO9pYSXq-QvgbM%252C_%253BB_ypq20yGKrazM%252C6dhDHhJGcmvL6M%252C_%253BivTDs79HInLVcM%252CU9G_8UXPMlqatM%252C_%253BsKMM4eBjWSQEBM%252CB51x0PBR9KNzvM%252C_&usg=AI4_-kQ1KyAM-NBtqXYuQj-aW0cS6fQ8kg&sa=X&ved=2ahUKEwibuuy9kqiHAxXnKkQIHS0YBqgQ_B16BAg9EAE#vhid=tYmxDgFq4MrkJM&vssid=mosaic');
    animation: scrollBackground 10s linear infinite;
}

@keyframes scrollBackground {
from {
    background-position: 0 0;
}
to {
    background-position: 0 100%;
}
}